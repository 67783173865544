.mark,
mark {
  display: flex;
  padding: 4px 8px;
  border-radius: 5px;
  color: #fff;
  background-color: color(primary);
  font: font(caption);
  font-weight: 700;
}
