@use '@angular/material' as mat;

body {
  @include mat.form-field-density(-4);

  // --mdc-filled-text-field-input-text-color: #fff;
  --mdc-filled-text-field-caret-color: #fff;

  .mat-mdc-form-field-hint-wrapper {
    padding: 0 8px;
  }

  .mat-mdc-text-field-wrapper {
    overflow: visible;
    border-radius: 5px;

    .mdc-line-ripple {
      display: none;
    }
  }

  .mat-mdc-form-field-focus-overlay {
    overflow: hidden;
    border-radius: 5px;
  }

  .mat-mdc-form-field-infix {
    label {
      position: absolute;
      bottom: calc(100% + 4px);
      left: -15px;
      font: font(caption);
      font-weight: 900;
    }
  }

  .mat-mdc-form-field {
    width: 100%;
    padding-top: 20px;
  }

  .mat-mdc-form-field-hint-end {
    display: flex;
    align-items: center;
    height: 24px;
    gap: 8px;
  }
}
