@use 'sass:map';
@use '@angular/material' as mat;

$text: #9e9e9e;

/**
 * Project variables
 */
$border-color-1: rgb(69, 66, 66);
$border-color-2: rgb(125, 122, 122);

/**
 * Material related
 */

$dark-primary-text: rgba(black, 0.87);
$light-primary-text: white;

$mat-primary: (
  50: #f2ebff,
  100: #dfcefe,
  200: #c9adfe,
  300: #b48cff,
  400: #040305,
  500: #935afc,
  600: #8b52fc,
  700: #8048fb,
  800: #763ffb,
  900: #642efa,
  A100: #ffffff,
  A200: #ffffff,
  A400: #dfd4ff,
  A700: #ccbbff,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text,
  ),
);

$mat-accent: (
  50: #eceff1,
  100: #cfd8dc,
  200: #b0bec5,
  300: #90a4ae,
  400: #78909c,
  500: #607d8b,
  600: #546e7a,
  700: #455a64,
  800: #37474f,
  900: #263238,
  A100: #cfd8dc,
  A200: #b0bec5,
  A400: #78909c,
  A700: #455a64,
  A900: #1a1a1a,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $light-primary-text,
    A900: $light-primary-text,
  ),
);

$mat-grays: (
  50: #fafafa,
  100: #f5f5f5,
  200: #eeeeee,
  300: #e0e0e0,
  400: #bdbdbd,
  500: #9e9e9e,
  600: #757575,
  700: #616161,
  800: #424242,
  900: #262626,
  A100: #ffffff,
  A200: #eeeeee,
  A400: #bdbdbd,
  A700: #616161,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $light-primary-text,
  ),
);

$mat-warn: mat.$m2-red-palette;
$mat-success: mat.$m2-green-palette;

$primary: mat.m2-define-palette($mat-primary);
$accent: mat.m2-define-palette($mat-accent);
$grays: mat.m2-define-palette($mat-grays);
$warn: mat.m2-define-palette($mat-warn);
$success: mat.m2-define-palette($mat-success);

$ff: 'Satoshi Variable, sans-serif';

$typography-config: mat.m2-define-typography-config(
  $font-family: $ff,
  $headline-1: mat.m2-define-typography-level(60px, 54px, 900, $ff),
  $headline-2: mat.m2-define-typography-level(40px, 54px, 900, $ff),
  $headline-3: mat.m2-define-typography-level(24px, 32px, 900, $ff),
  $headline-4: mat.m2-define-typography-level(18px, 24px, 700, $ff),
  $body-1: mat.m2-define-typography-level(16px, 24px, 500, $ff),
  $caption: mat.m2-define-typography-level(12px, 16px, 500, $ff),
  $button: mat.m2-define-typography-level(14px, 19px, 900, $ff),
);

$theme: mat.m2-define-dark-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
      grays: $grays,
      warn: $warn,
      success: $success,
    ),
    typography: $typography-config,
    density: 0,
  )
);
