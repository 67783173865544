body {
  .mat-expansion-panel-body {
    padding-top: 16px;
    background-color: color(grays, 700);
  }

  .mat-expansion-panel-header-description {
    justify-content: flex-end;
  }
}
