body {
  .mat-divider {
    --mat-divider-color: #{color(grays, 900)};

    position: relative;

    &[label]:before {
      content: attr(label);
      display: block;
      padding: 0 10px;
      background-color: #000;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
