body {
  .mat-icon {
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 20px;
  }

  .mat-mdc-outlined-button > .mat-icon {
    margin: 0;
  }
}
