@use 'sass:map';
@use 'sass:list';

@import '_available-phosphor-icons';

@font-face {
  font-family: 'Phosphor-Bold';
  src: url('/assets/fonts/Phosphor-Bold-subset.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

// DO NOT CHANGE THIS VARIABLE A SUBSET FONT
// IS GENERATED BASED ON THIS VARIABLE NAME
$iconsToInclude: 'compass', 'book-bookmark', 'graduation-cap', 'users', 'user',
  'microphone', 'squares-four', 'cards', 'youtube-logo', 'code-block',
  'paint-brush', 'megaphone', 'caret-left', 'caret-right', 'fingerprint',
  'trash-simple', 'article', 'sign-out', 'spotify-logo', 'pencil', 'plus-square',
  'minus-square', 'slideshow', 'x', 'envelope', 'password', 'lightbulb',
  'magnifying-glass', 'link-simple', 'gear-six';

.phb {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Phosphor-Bold' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @each $icon in $iconsToInclude {
    &.#{$icon}:before {
      content: map.get($availableIcons, $icon);
    }
  }
}
