body {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    @include frostedBg(0.2, #fff, 10px);
    border-radius: 8px;
  }

  .cdk-overlay-dark-backdrop {
    background-color: rgba(0, 0, 0, 0.72);
  }
}
