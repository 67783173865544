// .transition-page-title {
//   @media not (prefers-reduced-motion: reduce) {
//     view-transition-name: pageTitle;
//   }
// }

// ::view-transition-old(pageTitle) {
//   animation: fade 0.2s linear forwards;
// }

// ::view-transition-new(pageTitle) {
//   animation: fade 0.3s linear reverse;
// }

// @keyframes fade {
//   from {
//     opacity: 1;
//   }
//   to {
//     opacity: 0;
//   }
// }

::view-transition-old(root),
::view-transition-new(root) {
  animation-duration: 175ms;
}
