body {
  .mdc-button--unelevated {
    &.mat-primary {
      border: 2.4px solid rgba(0, 0, 0, 0.2);
      background: radial-gradient(50% 50% at 50% 50%, #a681ff 0%, #7b3af7 100%);
    }

    &.large {
      font: font('headline-4');
      padding: 0 16px;
      height: 54px;
    }

    &.mat-unthemed {
      --mdc-filled-button-container-color: #{color(accent, A900)};
    }

    .mdc-button__label {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      flex-wrap: nowrap;
      white-space: nowrap;
    }
  }
}
