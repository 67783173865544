/**
 * $bp-giga:  2500px;
 * $bp-mega:  1900px;
 * $bp-kilo:  1360px;
 * $bp-centi: 1024px;
 * $bp-milli:  768px;
 * $bp-nano:   480px;
 * $bp-pico:   320px;
  */

$bp-giga: 2500px;
$bp-mega: 1760px;
$bp-kilo: 1360px;
$bp-centi: 1024px;
$bp-milli: 768px;
$bp-nano: 480px;
$bp-pico: 360px;

$breakpoint-map: (
  giga: (
    $bp-giga,
  ),
  mega: (
    $bp-mega,
  ),
  kilo: (
    $bp-kilo,
  ),
  centi: (
    $bp-centi,
  ),
  milli: (
    $bp-milli,
  ),
  nano: (
    $bp-nano,
  ),
  pico: (
    $bp-pico,
  ),
);

@function to-string($value) {
  @return inspect($value);
}

/**
   *  First section controls media quries that contains from-to values
   *  if numbers are parsed unitless it will default to px's, if you need to use em's just parse the unit in the end
   *
   *  Example:
    @include breakpoint (0 nano) {
      // @media only screen and (min-width: 0px) and (max-width: 480px) {}
    }
    @include breakpoint (nano milli) {
      // @media only screen and (min-width: 480px) and (max-width: 768px) {}
    }
    @include breakpoint (1024 1360) {
      // @media only screen and (min-width: 1024px) and (max-width: 1360px) {}
    }
    @include breakpoint (milli 1024) {
      // @media only screen and (min-width: 768px) and (max-width: 1024px) {}
    }
   *  You can use this very simple and you can turn from min to max width
   *  only by adding dash infront of your breakpoint keyword
   *
   *  Example:
    @include breakpoint (milli) {
      // @media only screen and (min-width: 768px) {}
    }
    @include breakpoint (-nano) {
      // @media only screen and (max-width: 480px) {}
    }
   *  There is also support for writing strings, and printable breakpoint
   *
   *  Example:
    @include breakpoint ('max-width: 700px') {
      // @media only screen and (max-width: 700px) {}
    }
    @include breakpoint (print) {
      // @media print {}
    }
   */
@mixin breakpoint($point) {
  @if length($point) > 1 {
    $point1: nth($point, 1);
    $point2: nth($point, 2);

    @if type-of($point1) == 'number' and unitless($point1) {
      $point1: nth($point, 1) + 0px;
    }

    @if type-of($point2) == 'number' and unitless($point2) {
      $point2: nth($point, 2) + 0px;
    }

    @if type-of($point1) == 'number' and type-of($point2) == 'number' {
      @media only screen and (min-width: $point1) and (max-width: $point2) {
        @content;
      }
    } @else if type-of($point1) == 'number' {
      @media only screen and (min-width: $point1) and (max-width: map-get($breakpoint-map, $point2)) {
        @content;
      }
    } @else if type-of($point2) == 'number' {
      @media only screen and (min-width: map-get($breakpoint-map, $point1)) and (max-width: $point2) {
        @content;
      }
    } @else {
      @media only screen and (min-width: map-get($breakpoint-map, $point1)) and (max-width: map-get($breakpoint-map, $point2)) {
        @content;
      }
    }
  } @else {
    @if $point == giga or $point == -giga {
      @if str-index(to-string($point), '-') == 1 {
        @media only screen and (max-width: $bp-giga) {
          @content;
        }
      } @else {
        @media only screen and (min-width: $bp-giga) {
          @content;
        }
      }
    } @else if $point == mega or $point == -mega {
      @if str-index(to-string($point), '-') == 1 {
        @media only screen and (max-width: $bp-mega) {
          @content;
        }
      } @else {
        @media only screen and (min-width: $bp-mega) {
          @content;
        }
      }
    } @else if $point == kilo or $point == -kilo {
      @if str-index(to-string($point), '-') == 1 {
        @media only screen and (max-width: $bp-kilo) {
          @content;
        }
      } @else {
        @media only screen and (min-width: $bp-kilo) {
          @content;
        }
      }
    } @else if $point == centi or $point == -centi {
      @if str-index(to-string($point), '-') == 1 {
        @media only screen and (max-width: $bp-centi) {
          @content;
        }
      } @else {
        @media only screen and (min-width: $bp-centi) {
          @content;
        }
      }
    } @else if $point == milli or $point == -milli {
      @if str-index(to-string($point), '-') == 1 {
        @media only screen and (max-width: $bp-milli) {
          @content;
        }
      } @else {
        @media only screen and (min-width: $bp-milli) {
          @content;
        }
      }
    } @else if $point == nano or $point == -nano {
      @if str-index(to-string($point), '-') == 1 {
        @media only screen and (max-width: $bp-nano) {
          @content;
        }
      } @else {
        @media only screen and (min-width: $bp-nano) {
          @content;
        }
      }
    } @else if $point == pico or $point == -pico {
      @if str-index(to-string($point), '-') == 1 {
        @media only screen and (max-width: $bp-pico) {
          @content;
        }
      } @else {
        @media only screen and (min-width: $bp-pico) {
          @content;
        }
      }
    } @else if $point == iphoneX {
      @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
        @content;
      }
    } @else if $point == iphoneX-portrait {
      @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
        @content;
      }
    } @else if $point == iphoneX-landscape {
      @media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape) {
        @content;
      }
    } @else if $point == print {
      @media print {
        @content;
      }
    } @else if type-of($point) == 'number' {
      @media only screen and (min-width: $point) {
        @content;
      }
    } @else if str-index($point, max) == 1 or str-index($point, min) == 1 {
      @media only screen and (#{$point}) {
        @content;
      }
    }
  }
}
