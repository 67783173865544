.btn {
  display: inline-flex;
  gap: 8px;
  padding: 10px 12px;
  border: 0;
  border-radius: 8px;
  color: #fff;
  font: font(button);
  align-items: center;
  justify-content: flex-start;
  background: linear-gradient(180deg, rgba(145, 89, 252, 0) 0%, rgba(145, 89, 252, 0.44) 100%);
  box-shadow: 0 0 0 1px rgba(145, 89, 252, 0.5) inset;

  .mat-icon {
    width: 20px;
    height: 20px;
    font-size: 20px;
    line-height: 22px;
  }

  div {
    display: flex;
    flex-direction: column;
    text-align: left;
    line-height: 14px;
  }

  span.small {
    font-size: 8px;
    line-height: 10px;
  }

  &.accent {
    background: linear-gradient(180deg, rgba(145, 89, 252, 0) 0%, rgba(49, 255, 106, 0.44) 100%);
    box-shadow: 0 0 0 1px rgba(49, 255, 106, 0.5) inset;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

button.loading {
  color: transparent;
  position: relative;

  .mdc-button__label {
    opacity: 0;
  }

  &:before {
    content: '';
    width: 24px;
    height: 24px;
    border: 2px solid #fff;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    position: absolute;
    transform: translate(-50%, -50%);
  }
}
