* {
  box-sizing: border-box;
}

:root {
  background-color: #000;
  color: #fff;
}

html,
body,
app-root {
  height: 100vh;
  height: 100dvh;
  overflow: hidden;
}

body {
  -webkit-font-smoothing: antialiased;
  font: font('body-1');
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
[class^='mat-headline-'] {
  margin: 0;
}

.mat-button {
  font: font('button');
}

.mat-caption {
  font: font('caption');
  color: $text;
}
